import {defineStore} from "pinia";
import {post_user_data_to_api} from "@/helpers/api-helpers"

export const userAuthenticatedStore = defineStore({
    id: "userAuthenticated",
    state: () => ({
        username:"",
        password:"",
        display_auth_popup:false,
        token:undefined,
        confirm_auth:undefined,
        cancel_auth:undefined,
    }),
    getters: {

    },
    actions: {
        async login() {
            this.token = await post_user_data_to_api(this.username, this.password)

            if (this.token) {
                this.username = ""
                this.password = ""
                this.confirm_authentication()
            }
        },
        cancel() {
            this.username = ""
            this.password = ""
        },
        trig_authentication() {
            this.display_auth_popup = true
            this.token = undefined
            let res, rej
            let status_promise = new Promise(function(resolve, reject) {
                res = resolve
                rej = reject
            })
            this.confirm_auth = res
            this.cancel_auth = rej
            return status_promise
        },
        confirm_authentication() {
            this.display_auth_popup = false
            this.confirm_auth()
        },
    }
})


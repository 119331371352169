<script setup>
import {subjectsStore} from "@/stores/subjects";
import {defineProps} from "vue";

const store = subjectsStore()

defineProps({
  id: Number,
  title: String,
  abrev: String,
  desc: String,
})
</script>

<template>
  <div class="subject_card">
    <router-link class="router-link" :to="{name: 'EditSubject', params: {id:id}}">
      <h3>{{ title }}</h3>
    </router-link>
    <div class="subject_icon edit" @click="store.enable_editing(id)"></div>
    <div class="subject_icon star starred"></div>
    <div class="label_list">
      <span class="subject_label">1ière</span>
      <span class="subject_label">Général</span>
      <span class="subject_label">Spé</span>
    </div>
  </div>
</template>


<style scoped>
.subject_card {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  border: var(--subtitle-color) solid 1px;
  border-radius: 0.5em;
  padding: 0 0.5em 0.2em 0.5em;
  background-color: white;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(125,125,125,.5);
  text-align: center;
  overflow: hidden;
}

.subject_card:hover {
  background: var(--selected-element-color);
  border-color: white;
}

.subject_card h3 {
  color: var(--main-color);
  margin: 0.5em 1em 2em 1em;
}

.subject_card:hover h3 {
  color: white;
}

.subject_icon {
  width: 1em;
  height: 1em;
  transition: width 0.2s, height 0.2s;
  background-size: cover;
  position: absolute;
  top: 0.5em;
  filter: opacity(0.7);
}

.subject_icon:hover {
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
}

.edit {
  background-image: url("../../assets/editer.png");
  right: 0.5em;
}

.star {
  background-image: url("../../assets/etoile.png");
  left: 0.5em;
}

.starred {
  background-image: url("../../assets/etoile-pleine.png");
  left: 0.5em;
}

/* Subjects Labels */
.label_list {
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.subject_label {
  margin: 0.2em;
  padding: 2px;
  border: 1px solid gray;
  border-radius: 0.3em;
  background-color: ghostwhite;
  height: fit-content;
  font-size: 0.7em;
  transition: font-size 0.2s;
}

.subject_label:hover {
  font-size: 0.8em;
  cursor: pointer;
}
</style>
import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
    path: '/', name: 'Home', component: HomeView
}, {
    path: '/evaluate/evaluation/:id',
    name: 'EvaluateEvaluation',
    component: () => import(/* webpackChunkName: "Evaluate" */ '../views/Evaluate.vue')
}, {
    path: '/edit/subject/:id',
    name: 'EditSubject',
    component: () => import(/* webpackChunkName: "EditSubject" */ '../views/SubjectEdit.vue')
}, {
    path: '/edit/evaluation/:id',
    name: 'EditEvaluation',
    component: () => import(/* webpackChunkName: "EditEvaluation" */ '../views/EvaluationEdit.vue')
},]

const router = createRouter({
    history: createWebHashHistory(), routes
})

export default router

<script setup>
import school_subject_card from '../components/subjects/school_subject_card.vue'
import {onMounted} from "vue";
import {subjectsStore} from "@/stores/subjects";

const store = subjectsStore()

onMounted(async () => {
  /* Calling API to get the evaluation data from database */
  try {
    await store.fetch_school_subjects()
  } catch (error) {
    console.log(error);
  }
})

</script>

<template>
  <header>
    <div class="titles">
      <h1>Matières</h1>
      <input type="button" value="Créer" @click="store.create_new_subject()">
    </div>
    <div class="right_corner">
      <label for="subjects_filter_input">Filtrer :</label>
      <input type="text" id="subjects_filter_input" v-model="store.filter_value">
    </div>

  </header>
  <section class="">

    <h2>Favoris</h2>
    <div class="subjects_list">

    </div>
    <h2>Toutes</h2>
    <div class="subjects_list">
      <school_subject_card v-for="subject in store.schoolSubjects"
                           :id="subject.subj_id"
                           :title="subject.subj_title"
                           :abrev="subject.subj_abbreviation"
                           :desc="subject.subj_description"
                           :key="subject.subj_id"
      ></school_subject_card>
    </div>
  </section>

  <!--  Edition popup for subject creation/modification-->

  <div class="edit_subject popup" v-if="store.editing_subject_flag">
    <div class="edit_fields">
      <label for="subject_title">Titre</label>
      <input type="text" id="subject_title" v-model="store.editedSubject.subj_title">
      <label for="subject_shorthand">Abbréviation</label>
      <input type="text" id="subject_shorthand" v-model="store.editedSubject.subj_abbreviation">
      <label for="subject_description">Description</label>
      <textarea name="subject_description"
                id="subject_description"
                rows="5"
                v-model="store.editedSubject.subj_description"
      >
            </textarea>
    </div>
    <div class="edit_actions">
      <input type="button" value="Valider" @click="store.send_subject_values()">
      <input type="button" value="Annuler" @click="store.cancel_editing()">
      <input type="button" value="Supprimer" @click="store.delete_subject()">
    </div>
  </div>


</template>

<script>
export default {
  name: "HomeView",
  components: {}

}
</script>

<style scoped>

header .titles, header .right_corner {
  display: flex;
}

header input[type="button"] {
  display: inline;
  margin-left: 1em;
  margin-bottom: 0.4em
}

#subjects_filter_input {
  width: 20vw;
  margin-left: 0.5em;
  transition: width 0.2s;
}

#subjects_filter_input:focus {
  width: 33vw;
}

.subjects_list {
  margin: 0em 1.5em 1.5em 1.5em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1em;
}

h2 {

}

/* Edition popup */
.edit_subject {
  width: 50vw;
}

.edit_fields {
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  color: white;
}

.edit label {
  text-shadow: 0px 1px 0px rgba(244, 243, 243, 0.3),
  0px -1px 0px rgba(0, 0, 0, .7);
}

.edit_fields input, textarea {
  margin: 0 0 1em 0;
  padding: 0.5em;
  font-size: 1.1em;
  border: unset;
  background: #fff;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(125, 125, 125, .5);
}

.edit_fields input {
  height: 1.2em;
}

</style>
import {defineStore} from "pinia";
import {fetch_referential_data, post_element_to_api, request_delete_to_api} from "@/helpers/api-helpers";

export const subjectsStore = defineStore({
    id: 'subjects',
    state: () => ({
        editing_subject_flag: false,
        school_subjects: [],
        edited_subject: null,
        filter_value:"",
    }),
    getters: {
        editingFlag: (state) => state.editing_subject_flag,
        schoolSubjects: (state) => state.school_subjects.filter((elem) => (
            elem.subj_title.includes(state.filter_value)
            || elem.subj_abbreviation.includes(state.filter_value)
        )),
        editedSubject: (state) => state.edited_subject,
    },
    actions: {
        async fetch_school_subjects() {
            // Fetch school subjects from API
            let subj_url = 'subjects/';
            //TODO: Manage subjects API connectivity error
            this.school_subjects = await fetch_referential_data(subj_url)
        },
        enable_editing(subject_id) {
            // Enabling edit popup
            this.editing_subject_flag = true
            // Looking for corresponding subject data in state
            let edited_subject = this.school_subjects.find((elem) => elem.subj_id === subject_id)
            // Copying the object so modifications are not instantly applied to original subjects map
            this.edited_subject = JSON.parse(JSON.stringify(edited_subject))
        },
        cancel_editing() {
            // Resetting editing properties in state
            this.editing_subject_flag = false
            this.edited_subject = null
        },
        create_new_subject() {
            // Creating empty subject object with ID=-1 to indicate API to create a new.
            this.edited_subject = {
                subj_id: -1,
                subj_title: "",
                subj_abbreviation: "",
                subj_description: ""
            }
            // Enabling editing popup
            this.editing_subject_flag = true
        },
        async send_subject_values() {
            // Send Object values to API for creation/modification
            let response = await post_element_to_api("subjects/edit/", this.edited_subject)

            if (this.edited_subject.subj_id === -1) {
                // Getting the ID created in database
                let new_id = Number(await response.text())
                // Adding the new object to local map with its ID
                this.school_subjects.push({
                    subj_id: new_id,
                    subj_title: this.edited_subject.subj_title,
                    subj_abbreviation: this.edited_subject.subj_abbreviation,
                    subj_description: this.edited_subject.subj_description
                })
            } else {
                // Updating local copy of object in state
                let original_subject = this.school_subjects
                    .find((elem) => elem.subj_id === this.edited_subject.subj_id)
                original_subject.subj_title = this.edited_subject.subj_title
                original_subject.subj_abbreviation = this.edited_subject.subj_abbreviation
                original_subject.subj_description = this.edited_subject.subj_description
            }
            // Init editing properties in state
            this.editing_subject_flag = false
            this.edited_subject = null
        },
        async delete_subject() {
            let delete_url = 'subjects/edit/' + this.edited_subject.subj_id
            await request_delete_to_api(delete_url)
            // TODO: Handle API fail responses on subject deletion

            // Deleting the object from state subjects collection
            this.school_subjects = this.school_subjects.filter((elem) => elem.subj_id !== this.edited_subject.subj_id)
            // Init editing properties in state
            this.editing_subject_flag = false
            this.edited_subject = null
        },
    }

})
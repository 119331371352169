<script setup>
import security_authentication_popup from '@/components/security/security_authentication_popup.vue'
import {userAuthenticatedStore} from '@/stores/user_authenticated'

const auth_store = userAuthenticatedStore()

</script>

<template>
  <router-view :key="$route.path"/>
  <security_authentication_popup v-if="auth_store.display_auth_popup"></security_authentication_popup>
</template>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root{
  /* COLORS */
  --subtitle-color: #3d4c4d;
  --section-background: #ebe0d5;
  --main-color: rgba(90, 110, 100, 0.95);
  --main-color-light: rgba(150, 175, 162, 0.5);
  --alternative-main-color: rgba(232, 202, 172, 0.9);
  --highlight-color: rgba(150, 90, 40, 0.8);
  --highlight-color-extralight: rgb(248, 207, 167);
  --selected-element-color: linear-gradient(90deg, rgba(53, 79, 76, 1) 0%, rgba(107, 146, 156, 1) 100%);
  --selected-option-color: rgba(45, 70, 70, 0.90);
  --commands-background: rgba(25, 25, 25, .5);
  /* FONTS */
  --codes-font: Rajdhani;
  --header-font: Abhaya;
  --subtitles-font: Abhaya;
  --labels-font: Abhaya;
  --main-font: Lato;
  --results-fonts: blackjack;
}

@font-face {
    font-family: "Rajdhani";
    src: url("assets/fonts/rajdhani/rajdhani-light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Rajdhani";
    src: url("assets/fonts/rajdhani/rajdhani-bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Abhaya";
    src: url("assets/fonts/abhaya-libre/AbhayaLibre-ExtraBold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Abhaya";
    src: url("assets/fonts/abhaya-libre/AbhayaLibre-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Lato";
    src: url("assets/fonts/lato/Lato-Light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "blackjack";
  src: url("assets/fonts/blackjack/blackjack.otf");
  font-weight: normal;
  font-style: normal;
}


body {
  background-color: #f6faf6;
}

#app {
  font-family: var(--main-font);
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  margin: 0 auto;
  width: 85vw;
  display: flex;
}

header * {
  display: block;
  align-self: end;
  height: fit-content;
  margin-bottom: 0;
  margin-top: 0;
  font-family: var(--header-font);
}

header .titles {
  font-family: var(--header-font);
}

header .right_corner {
  margin-left: auto;
  margin-bottom: 0.3em;
}

header img:hover {
  height: 50px;
}

section {
  margin: 0 auto;
  width: 85vw;
  box-sizing: border-box;
  background-color: var(--section-background);
  border: 1px solid black;
  padding: 1.5em;
}

h2 {
  border-bottom: 1px solid dimgray;
  margin: 0 0 0.7em 0;
  color: var(--subtitle-color);
  font-family: var(--subtitles-font);
  text-shadow: 0px 1px 0px rgba(255, 255, 255, .3), 0px -1px 0px rgba(0, 0, 0, .7);
}

label {
  font-family: var(--labels-font);
}

.router-link {
  all: unset;
  cursor: zoom-in;
}

input[type="button"] {
  font-weight: normal;
  font-size: 1em;
  padding: 0.3em 0.5em 0.2em 0.5em;
}

input[type="text"], textarea, input[type="number"] {
  font-family: var(--main-font);
  font-size: 1.1em;
  border: 1px solid gray;
  background: #fff;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(125, 125, 125, .5);
}

input[type="number"] {
    width: 4em;
    text-align: center;
}

.info {
  font-style: italic;
}

.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 1em 1em 3em;
  max-height: 90vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  background-color: var(--main-color);
  box-shadow: 0 0 15vw rgba(75, 75, 75, 0.5),
              10px 10px 10px rgba(75, 75, 75, 0.7),
              inset -3px -3px 15px rgba(75, 75, 75, 0.3);
  border: 1px solid var(--selected-option-color);
  border-radius: 0.2em;
}

.popup .edit_actions {
  position: absolute;
  left: 50%;
  width: 50%;
  bottom: 0.5em;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}


</style>

import {userAuthenticatedStore} from '@/stores/user_authenticated'

export async function post_user_data_to_api(username, password) {
    //
    let base_url = process.env.VUE_APP_API_BASE_URL
    let login_url = base_url + 'auth/token'

    // Preparing the url encoded values
    let body = new URLSearchParams({
        'username' : username,
        'password' : password
    })

    // Setting headers for request
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    // Shooting post request to server
    let response = await fetch(login_url, {
        method: "post",
        headers: myHeaders,
        body: body
    })

    let response_json = await response.json()
    if (response.ok) {
        return response_json.access_token
    } else {
        return false
    }
}

export async function post_element_to_api(api_ressource, element) {
    const auth_store = userAuthenticatedStore();

    // TODO : Détecter l'échec de l'authentification et déclencher la popup de connexion
    // Preparing usefull variables
    let base_url = process.env.VUE_APP_API_BASE_URL
    let url = base_url + api_ressource

    // Build post request body from element object passed
    let request_body = JSON.stringify(element)

    // Setting headers for request
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Content-Length", request_body.length.toString());
    myHeaders.append("Authorization", "Bearer " + auth_store.token);

    // Shooting post request to server
    let response = await fetch(url, {
        method: "post",
        headers: myHeaders,
        body: request_body
    })

    // Managing Authentication issue
    while (response.status === 401) {
        // Awaiting user to connect
        await auth_store.trig_authentication()
        // Adding new token to request's headers
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Content-Length", request_body.length.toString());
        myHeaders.append("Authorization", "Bearer " + auth_store.token);
        response = await fetch(url, {
            method: "post",
            headers: myHeaders,
            body: request_body
        })
    }

    return response
}

export async function fetch_evaluation_data(eval_id) {
    const auth_store = userAuthenticatedStore();

    let base_url = process.env.VUE_APP_API_BASE_URL
    let eval_url = base_url + 'evaluate/evaluation/' + eval_id;

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth_store.token);

    //TODO: Manage evaluation API connectivity error
    let response = await fetch(eval_url, {headers: myHeaders});

    // Managing Authentication issue
    while (response.status === 401) {
        // Awaiting user to connect
        await auth_store.trig_authentication()
        // Adding new token to request's headers
        myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth_store.token);
        response = await fetch(eval_url, {headers: myHeaders})
    }

    // Parsing response data
    let evaluation_data = await response.json()
    /* --------------------------------------------------------------------
    Restructuring data in order to get an object skill > capacities > notes
    */
    let evaluated_skills = new Map
    // Iterate through capacities
    evaluation_data.evaluates.forEach((evaluated) => {
        let ev = JSON.parse(JSON.stringify(evaluated))  // Deep copy the object in order to avoid source modifications
        // Remove skill property from capacity object, since we will access cap through skill
        delete ev.capacity.included_in.skill
        let skill = JSON.parse(JSON.stringify(evaluated.capacity.included_in.skill))
        // If skill code is not already in skills keys
        if (!evaluated_skills.has(skill.skill_code)) {
            // initiate a new array property for the current skill object
            skill["capacities"] = []
            evaluated_skills.set(skill.skill_code, skill)
        }
        // Reconstruct Capacity object with associated performances and criteria
        let capacity = ev.capacity
        let perfs = ev.performances
        let type = ev.eval_type
        let criteria = ev.criteria
        let evaluates_id = ev.evaluates_id
        let evaluates_weight = ev.evaluates_weight

        capacity["performances"] = perfs
        capacity["eval_type"] = type
        capacity["criteria"] = criteria
        capacity["evaluates_weight"] = evaluates_weight
        capacity["evaluates_id"] = evaluates_id // This ID is required for updating notes in database

        // Add capacity object in skill's capacities list
        evaluated_skills.get(skill.skill_code)["capacities"].push(capacity)
    })

    return [evaluation_data, evaluated_skills]
}

export async function fetch_referential_data(api_ressource) {
    const auth_store = userAuthenticatedStore();

    let base_url = process.env.VUE_APP_API_BASE_URL
    let url = base_url + api_ressource

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth_store.token);

    let response = await fetch(url, {headers: myHeaders})

    // Managing Authentication issue
    while (response.status === 401) {
        // Awaiting user to connect
        await auth_store.trig_authentication()
        // Adding new token to request's headers
        myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth_store.token);
        response = await fetch(url, {headers: myHeaders})
    }

    return await response.json()
}

export async function request_delete_to_api(delete_target_url) {
    const auth_store = userAuthenticatedStore();

    let base_url = process.env.VUE_APP_API_BASE_URL
    let url = base_url + delete_target_url

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth_store.token);

    let response = await fetch(url, {
        method: "delete",
        headers: myHeaders
    })

    // Managing Authentication issue
    while (response.status === 401) {
        // Awaiting user to connect
        await auth_store.trig_authentication()
        // Adding new token to request's headers
        myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth_store.token);
            response = await fetch(url, {
            method: "delete",
            headers: myHeaders
        })
    }

    return response.ok
}
<script setup>
  import { userAuthenticatedStore} from "@/stores/user_authenticated";
  const auth_store = userAuthenticatedStore();

</script>

<template>
  <div class="security_popup popup">
    <div class="auth_fields" id="auth">
      <label for="username">Utilisateur :</label>
      <input type="text" name="username" id="username" v-model="auth_store.username">

      <label for="password">Mot de passe :</label>
      <input type="password" name="password" id="password" v-model="auth_store.password">

      <input type="button" value="Connexion" @click="auth_store.login()">
      <input type="button" value="Annuler" @click="auth_store.cancel()">
    </div>
  </div>
</template>

<style scoped>

</style>